/** @jsx jsx */
import { jsx } from "theme-ui"

import Volunteers from "../components/forms/volunteers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const Page = () => (
  <Layout title="Volontari">
    <SEO title="Volontari" />
    <Container sx={{ maxWidth: 600, px: [4, null], textAlign: `justify` }}>
      <p>
        Come già avete fatto in tanti, per quanti ancora volessero provare a
        vincere questa sfida dando il proprio tempo libero, le proprie idee, la
        propria passione, lasciate i vostri contatti.{" "}
      </p>
    </Container>
    <Volunteers />
  </Layout>
)

export default Page
