/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Button, Input, Label, Checkbox, Spinner, Select } from "theme-ui"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import isEmail from "validator/lib/isEmail"
import Form from "../form"
import listComuni from "../../comuni"
import Privacy from "./privacy"

import { FiSend } from "react-icons/fi"

export default () => {
  const [userEmail, setUserEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [provincia, setProvincia] = useState("")
  const [comuni, setComuni] = useState([])
  const [error, setError] = useState("")
  const [togglePrivacy, setTogglePrivacy] = useState(false)
  const [formResponse, setFormResponse] = useState("")
  const url = process.env.NETLIFY_FUNCTIONS_URL || ""

  useEffect(() => {
    setComuni([])
    const selectedComuni = listComuni[provincia]
    if (selectedComuni) {
      setComuni(selectedComuni.map(comune => comune))
    }
  }, [provincia])

  function addEmail(e) {
    e.preventDefault()
    setError("")
    setIsLoading(true)

    if (
      !userEmail ||
      !document.volunteers.nome.value ||
      !document.volunteers.cognome.value ||
      !document.volunteers.provincia.value
    ) {
      setIsLoading(false)
      setError("Tutti i campi sono obbligatori")
      return
    }

    if (!isEmail(userEmail)) {
      setIsLoading(false)
      setError("E-mail non valida")
      return
    }

    trackCustomEvent({
      category: "Volunteer",
      action: "Submit",
    })

    fetch(`${url}/.netlify/functions/subscribe`, {
      method: "POST",
      body: JSON.stringify({
        email: userEmail,
        volunteer: true,
        nome: document.volunteers.nome.value,
        cognome: document.volunteers.cognome.value,
        comune: document.volunteers.comune.value,
        provincia: document.volunteers.provincia.value,
        cellulare: document.volunteers.cellulare.value,
        firstCall: document.volunteers.firstCall.value,
        opzione1: document.volunteers.opzione1.checked,
        opzione2: document.volunteers.opzione2.checked,
        opzione3: document.volunteers.opzione3.checked,
        opzione4: document.volunteers.opzione4.checked,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false)
        setUserEmail("")
        setFormResponse(data.message)
      })
      .catch(error => {
        setIsLoading(false)
        setUserEmail("")
        setFormResponse("Errore, riprova più tardi.")
      })
  }

  return (
    <Form title="Partecipa">
      {error && <p sx={{ color: `white`, pt: 2 }}>{error}</p>}
      {formResponse === "" && !isLoading && (
        <form name="volunteers" onSubmit={addEmail}>
          <Label htmlFor="nome">Nome</Label>
          <Input
            type="text"
            id="nome"
            name="nome"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />
          <Label htmlFor="cognome">Cognome</Label>
          <Input
            type="text"
            name="cognome"
            id="cognome"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />
          <Label htmlFor="email">Indirizzo E-Mail</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            sx={{
              variant: `input.newsletter`,
            }}
            required
          />
          <Label htmlFor="cellulare">Cellulare</Label>
          <Input
            type="phone"
            name="cellulare"
            id="cellulare"
            sx={{
              variant: `input.newsletter`,
            }}
          />
          <h3 sx={{ mt: 3, mb: 2 }}>Come vuoi aiutarci?</h3>
          <Label htmlFor="opzione1">
            <Checkbox
              name="opzione1"
              id="opzione1"
              sx={{
                variant: `input.newsletter.checkbox`,
              }}
            />{" "}
            <div sx={{ ml: [3, 0] }}>Volontari social</div>
          </Label>
          <Label htmlFor="opzione2">
            <Checkbox
              name="opzione2"
              id="opzione2"
              sx={{
                variant: `input.newsletter.checkbox`,
              }}
            />{" "}
            <div sx={{ ml: [3, 0] }}>
              Contribuisci alla redazione del programma
            </div>
          </Label>
          <Label htmlFor="opzione3">
            <Checkbox
              name="opzione3"
              id="opzione3"
              sx={{
                variant: `input.newsletter.checkbox`,
              }}
            />{" "}
            <div sx={{ ml: [3, 0] }}>
              Organizza eventi/gazebo/punti informativi sul tuo territorio
            </div>
          </Label>
          <Label htmlFor="opzione4">
            <Checkbox
              name="opzione4"
              id="opzione4"
              sx={{
                variant: `input.newsletter.checkbox`,
              }}
            />{" "}
            <div sx={{ ml: [3, 0] }}>
              Metti a disposizione le tue ore e lavora al fianco di Arturo
            </div>
          </Label>
          <h3 sx={{ mt: 3, mb: 2 }}>Da dove vieni?</h3>
          <Label htmlFor="provincia">Provincia</Label>
          <Select
            name="provincia"
            id="provincia"
            sx={{
              variant: `input.newsletter`,
            }}
            onChange={e => setProvincia(e.target.value)}
            required
          >
            <option value="">Seleziona</option>
            <option value="Belluno">Belluno</option>
            <option value="Padova">Padova</option>
            <option value="Rovigo">Rovigo</option>
            <option value="Treviso">Treviso</option>
            <option value="Venezia">Venezia</option>
            <option value="Verona">Verona</option>
            <option value="Vicenza">Vicenza</option>
          </Select>
          <Label htmlFor="comune">Comune</Label>
          <Select
            name="comune"
            id="comune"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          >
            <option value="">---</option>
            {comuni.map((comune, index) => (
              <option key={index} value={comune}>
                {comune}
              </option>
            ))}
          </Select>
          <Label htmlFor="firstCall">
            Quando è un buon momento per chiamarti?
          </Label>
          <Select
            name="firstCall"
            id="firstCall"
            sx={{
              variant: `input.newsletter`,
            }}
            required
          >
            <option value="">Seleziona</option>
            <option value="Mattino">Mattino</option>
            <option value="Pomeriggio">Pomeriggio</option>
            <option value="Sera">Sera</option>
          </Select>
          <div sx={{ display: `flex` }}>
            <Label htmlFor="privacy">
              <Checkbox
                name="privacy"
                id="privacy"
                sx={{
                  variant: `input.newsletter.checkbox`,
                }}
                onClick={() => setIsChecked(!isChecked)}
                required
              />
              <div sx={{ ml: 3 }}>
                Dichiaro di aver letto e presto il consenso al trattamento dei
                dati personali sulla base della{" "}
                <a href="/privacy/" target="_blank" sx={{ color: `white` }}>
                  Privacy Policy
                </a>
              </div>
            </Label>
            <Button
              type="button"
              sx={{
                bg: `secondary`,
                fontStyle: `italic`,
                textDecoration: `underline`,
              }}
              onClick={() => setTogglePrivacy(!togglePrivacy)}
            >
              ?
            </Button>
          </div>

          {togglePrivacy && <Privacy setToggle={setTogglePrivacy} />}
          <Button
            name="submit"
            type="submit"
            sx={{
              variant: `input.newsletter.button`,
            }}
            disabled={!isChecked ? true : false}
          >
            Invia <FiSend sx={{ transform: `rotate(45deg)` }} />
          </Button>
        </form>
      )}
      {formResponse === "" && isLoading && (
        <Spinner sx={{ m: `auto`, color: `white` }} />
      )}
      {formResponse !== "" && !isLoading && (
        <p sx={{ color: `white`, pt: 2 }}>{formResponse}</p>
      )}
    </Form>
  )
}
